<template>
  <div class="flex flex-col w-full h-full max-h-full p-4 space-y-4 overflow-y-scroll policy">

    <router-link :to="{name:'home'}" class="w-10 h-10 font-bold">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="w-6 h-6 text-brand-dark">
        <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
          <path d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"/>
        </g>
      </svg>
    </router-link>

    <div class="px-6 py-6 overflow-scroll text-sm leading-normal prose break-words bg-white rounded-lg sm:prose-sm max-w-none">
      <div v-if="loading" class="flex flex-row items-start space-x-2">
        <icon-loading class="w-4 h-4 text-brand-dark animate-spin" />
        <p class="text-xs font-semibold">{{ $t('action.loading') }}</p>
      </div>
      <div v-else v-html="content" />
    </div>
  </div>
</template>


<script>
import api from 'utils/api'
// import { mapGetters } from "vuex";

import IconLoading from 'assets/icons/loading.svg'

export default {
  name: 'policy',
  components: {
    IconLoading
  },
  data: () => ({
    loading: true,
    content: null
  }),
  mounted() {
    api
      .get("privacy-app", { params: { language: /* this.getLanguage ||  */this.$i18n.locale }})
      .then((response) => {
        this.content = response.data
      })
      .catch(() => {
        this.content = '<p><i>Failed to load contents of Privacy &amp; Cookie policy.</i></p><p>Try again later or visit <a href="https://budd.be/policy" target="_blank" rel="noopener">https://budd.be/policy</a>.</p>'
      })
      .then(() => {
        this.loading = false
      })
  },
  /* computed: {
    ...mapGetters(["getLanguage"]),
  } */
}
</script>